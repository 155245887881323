<template>
  <div id="app">
    <template>
      <div class="swagger" id="swagger"></div>
    </template>
  </div>
</template>

<script>
import SwaggerUI from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';
import apiJson from '@/assets/api.json'
export default {
  name: "Swagger",
  mounted() {
      SwaggerUI({
          spec: apiJson,
          dom_id: '#swagger'
      })
  }
}
</script>
 